import React from "react";
import "./PrivacyPolicy.css";
import FooterLong from "./Footer"; // Import the Footer component

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Rando Privacy Policy</h1>
            <p>
                <strong>Effective Date: [Insert Date]</strong>
            </p>

            <h2>1. Information We Collect</h2>
            <h3>1.1 Personal Information</h3>
            <p>
                When you create an account or use Rando, we may collect personal information, including but not limited
                to:
            </p>
            <ul>
                <li>Username</li>
                <li>Email address</li>
                <li>Profile picture</li>
                <li>Location (if you choose to share it)</li>
            </ul>

            <h3>1.2 Content You Share</h3>
            <p>Any images, comments, or other content you upload or share on Rando will be collected and stored.</p>

            <h3>1.3 Usage Data</h3>
            <p>We may collect information about how you use Rando, including:</p>
            <ul>
                <li>Device information (type, operating system, version)</li>
                <li>Log data (IP address, browser type, pages visited)</li>
                <li>Interaction data (likes, shares, comments)</li>
            </ul>

            <h3>1.4 Cookies and Tracking Technologies</h3>
            <p>
                We may use cookies and similar technologies to enhance your experience. You can manage your cookie
                preferences through your browser settings.
            </p>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including:</p>
            <ul>
                <li>To create and manage your account</li>
                <li>To personalize your experience on Rando</li>
                <li>To communicate with you about updates, promotions, and features</li>
                <li>To improve our services and develop new features</li>
                <li>To monitor and analyze usage trends and preferences</li>
                <li>To ensure compliance with our terms of service</li>
            </ul>

            <h2>3. Sharing Your Information</h2>
            <p>
                We do not sell your personal information to third parties. However, we may share your information in the
                following circumstances:
            </p>
            <ul>
                <li>
                    <strong>With Your Consent:</strong> We may share your information if you give us explicit
                    permission.
                </li>
                <li>
                    <strong>Service Providers:</strong> We may employ third-party companies to assist us in providing
                    services, and they may have access to your personal information to perform tasks on our behalf.
                </li>
                <li>
                    <strong>Legal Compliance:</strong> We may disclose your information if required by law or to respond
                    to legal requests.
                </li>
            </ul>

            <h2>4. Data Security</h2>
            <p>
                We implement reasonable security measures to protect your personal information from unauthorized access,
                disclosure, or misuse. However, no method of transmission over the internet or electronic storage is
                100% secure. We cannot guarantee its absolute security.
            </p>

            <h2>5. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access and update your personal information.</li>
                <li>Delete your account and the associated data.</li>
                <li>Request a copy of the information we hold about you.</li>
                <li>Withdraw consent for data processing (where applicable).</li>
            </ul>
            <p>To exercise these rights, please contact us at [Insert Contact Email].</p>

            <h2>6. Children’s Privacy</h2>
            <p>
                Rando is not intended for use by individuals under the age of 13. We do not knowingly collect personal
                information from children. If we become aware that we have collected such information, we will take
                steps to delete it promptly.
            </p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
                Privacy Policy on this page and updating the effective date. Your continued use of Rando after any
                modifications will constitute your acknowledgment of the modifications and your consent to abide by and
                be bound by the modified policy.
            </p>

            <h2>8. Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
            <ul>
                <li>Email: randoapp2024@gmail.com</li>
            </ul>

            <p>Thank you for using Rando! Your privacy is important to us.</p>
            <FooterLong />
        </div>
    );
};

export default PrivacyPolicy;
