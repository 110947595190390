import "./App.css";
import Page from "./components/Page";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Terms from "./components/Terms";

function App() {
    return (
        <div className="App">
            <Helmet>
                <title>{`Rando - Daily photo challenges with friends`}</title>
                <meta
                    name="keywords"
                    content="photo challenge, daily, social media, rando, random photo challenge, fun"
                />
                <meta name="description" content="Daily photo challenges every day to complete with friends!" />
            </Helmet>
            <Router>
                <NavBar />
                <main>
                    <Routes>
                        <Route path="/" element={<Page />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/tos" element={<Terms />} />
                        {/* Add other routes as needed */}
                    </Routes>
                </main>
            </Router>
        </div>
    );
}

export default App;
