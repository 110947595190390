import React from "react";
import "./PrivacyPolicy.css";
import FooterLong from "./FooterLong"; // Import the Footer component

const Terms = () => {
    return (
        <div className="terms-of-service">
            <h1>Terms of Service</h1>

            <h2>1. Introduction</h2>
            <p>
                Welcome to Rando. By using our application, website, and related services (collectively, the "Service"),
                you agree to be bound by these Terms of Service ("Terms"), our Privacy Policy, and our End User License
                Agreement (EULA). Please read these Terms carefully before accessing or using our Service. If you do not
                agree to these Terms, you may not use the Service.
            </p>

            <h2>2. Acceptance of Terms</h2>
            <p>
                By creating an account, accessing, or using any part of the Service, you acknowledge that you have read,
                understood, and agreed to be bound by these Terms, including our EULA, which prohibits objectionable
                content and abusive users.
            </p>

            <h2>3. Eligibility</h2>
            <p>
                You must be at least 13 years of age to use the Service. By agreeing to these Terms, you represent and
                warrant that you are of legal age to form a binding contract with Rando and meet all the eligibility
                requirements.
            </p>

            <h2>4. End User License Agreement (EULA)</h2>
            <p>
                All users of the Service must agree to the EULA. This agreement outlines the rules for acceptable
                behavior and use of the Service. You agree not to post, share, or engage in any form of objectionable
                content, including but not limited to abusive, defamatory, obscene, offensive, or illegal materials.
            </p>

            <h3>No Tolerance for Objectionable Content</h3>
            <p>We maintain a strict policy against objectionable content. This includes, but is not limited to:</p>
            <ul>
                <li>Hate speech, discrimination, or incitement to violence.</li>
                <li>Harassment, abuse, or threats directed at other users.</li>
                <li>Content that is defamatory, libelous, or slanderous.</li>
                <li>Pornographic, obscene, or otherwise inappropriate material.</li>
            </ul>
            <p>Any violation of this policy will result in immediate suspension or termination of your account.</p>

            <h2>5. Prohibited Conduct</h2>
            <p>You agree not to engage in any of the following prohibited activities:</p>
            <ul>
                <li>Using the Service for any illegal purpose or in violation of any applicable laws.</li>
                <li>Posting, uploading, or sharing any harmful, abusive, defamatory, or objectionable content.</li>
                <li>
                    Impersonating any person or entity, or falsely stating or misrepresenting your affiliation with a
                    person or entity.
                </li>
                <li>Attempting to interfere with or disrupt the Service.</li>
            </ul>

            <h2>6. User Accounts and Security</h2>
            <p>
                You are responsible for maintaining the confidentiality of your login credentials and account security.
                You must notify us immediately of any unauthorized use of your account or any other breach of security.
            </p>

            <h2>7. Content Ownership and Responsibility</h2>
            <p>
                While you retain ownership of any content you upload or share on the Service, you grant Rando a
                worldwide, royalty-free, non-exclusive license to use, modify, display, and distribute such content. You
                are solely responsible for the content you upload, and you agree to comply with all applicable laws.
            </p>

            <h2>8. Suspension and Termination</h2>
            <p>
                We reserve the right to suspend or terminate your access to the Service at any time, with or without
                notice, for any reason, including but not limited to violating these Terms, our EULA, or any applicable
                laws.
            </p>

            <h2>9. Privacy Policy</h2>
            <p>
                Our Privacy Policy outlines how we collect, use, and protect your personal information. By using the
                Service, you agree to the terms of our Privacy Policy.
            </p>

            <h2>10. Disclaimer of Warranties</h2>
            <p>
                The Service is provided "as is" and "as available" without any warranties, express or implied. We do not
                guarantee that the Service will be error-free or uninterrupted, nor do we make any warranties as to the
                accuracy, reliability, or availability of the Service.
            </p>

            <h2>11. Limitation of Liability</h2>
            <p>
                In no event will Rando, its affiliates, officers, directors, employees, or agents be liable for any
                direct, indirect, incidental, special, or consequential damages arising out of or in connection with the
                use of the Service.
            </p>

            <h2>12. Changes to Terms</h2>
            <p>
                We reserve the right to modify or update these Terms at any time. Any changes will be effective
                immediately upon posting. Continued use of the Service following such changes constitutes your
                acceptance of the new Terms.
            </p>

            <h2>13. Governing Law</h2>
            <p>
                These Terms shall be governed by and construed in accordance with the laws of your Country/State,
                without regard to its conflict of law provisions.
            </p>
            <p>By using the Service, you agree to these Terms of Service and our EULA.</p>
            <FooterLong />
        </div>
    );
};

export default Terms;
