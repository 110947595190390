import React from "react";
import Main from "./Main";
import Footer from "./Footer";

export default function Page() {
    return (
        <div>
            <Main />
        </div>
    );
}
