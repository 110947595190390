import React from "react";
import "./Footer.css";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa"; // Import social media icons

export default function FooterLong() {
    return (
        <footer className="footer-long">
            <div className="footer-links">
                <a href="/privacy-policy" className="footer-link">
                    Privacy Policy
                </a>
                <a href="/contact-us" className="footer-link">
                    Contact Us
                </a>
                <a href="/tos" className="footer-link">
                    Terms of Service
                </a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <FaFacebook />
                </a>
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <FaTwitter />
                </a>
                <a
                    href="https://www.instagram.com/randoapp2024/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                >
                    <FaInstagram />
                </a>
            </div>
        </footer>
    );
}
