import React from "react";
import "./ContactUs.css"; // Optional: Create a CSS file for styling
import { FaEnvelope, FaInstagram, FaDiscord } from "react-icons/fa";
import Footer from "./Footer"; // Import the Footer component

const ContactUs = () => {
    return (
        <div className="contact-us">
            <h1>Contact Us</h1>
            <p className="header">If you have any questions or feedback, feel free to reach out to us!</p>
            <ul>
                <li>
                    <a href="mailto:randoapp2024@gmail.com" className="contact">
                        {" "}
                        <FaEnvelope /> Email Us
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.instagram.com/randoapp2024"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact"
                    >
                        <FaInstagram /> <div>Follow us on Instagram</div>
                    </a>
                </li>
                <li>
                    <a
                        href="https://discord.gg/ZSpjcB3VJw"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact"
                    >
                        <FaDiscord /> Join our Discord
                    </a>
                </li>
            </ul>
            <Footer />
        </div>
    );
};

export default ContactUs;
